/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect, useRef } from "react";
import HeaderLayout from "../../layouts/admin/HeaderLayout";
import FooterLayout from "../../layouts/admin/FooterLayout";
import Listfilter from "../../components/ListFilter";
import MaterialDataGrid from "../../components/DataTable";
import { axiosInstance } from "../../utils/commonInstance";
import useDataFetching from "../../hooks/useDataFatching";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import {
  addSerialNumber,
  changeArrayKeys,
  createHeaderMap,
  extractKeysFromArray,
  flattenObject1,
  flattenObjectForReportsPage,
  transformData,
} from "../../utils/commonFunction";
import { Speaker } from "feather-icons-react";

const fetchDataForPlatform = async (params, endpoint) => {
//   return await axiosInstance.get(`web/${endpoint}`, { params });
  return await endpoint === "getsalaryreport" ? axiosInstance.post(`web/${endpoint}`, { params }) : axiosInstance.get(`web/${endpoint}`, { params });
};

const ReportsList = ({
  title,
  dynamiColumns,
  endPoint,
  filterOption,
  columnOrder,
  excludeKeys,
  exportColumns,
  keyMapping,
}) => {
  const location = useLocation();
  const {
    state,
    columnFilters,
    setColumnFilters,
    setGlobalFilter,
    pagination,
    setPagination,
    manualFetchData,
  } = useDataFetching(fetchDataForPlatform, endPoint, title);
  const [calculations, setCalculations] = useState(0);

  const [modal, setModal] = useState({
    isOpen: false,
    headerContent: "",
    subHeaderContent: "",
    data: {},
    clicked: null,
    text: "",
  });

  const handlePreviewClick = (row) => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: true,
      header: "Image Preview",
      data: row,
    }));
    // Add your edit logic here
  };

  const handleClose = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: false,
      header: "",
      data: {},
    }));
  };
  useEffect(() => {
    // Assuming OpenNewPagination is an object with properties pageIndex and pageSize
    const calculation = pagination.pageIndex * pagination.pageSize;
    // Set test to 0 if OpenNewPagination.pageIndex is 0, otherwise use the calculation
    setCalculations(calculation);
  }, [pagination.pageIndex, pagination.pageSize]);

  const dynamicColumns = useMemo(() => {
    const columns = [];
    // Add dynamic columns
    columns.push({
      header: "Sr. No.",
      Cell: ({ row }) => {
        const number = calculations + (row?.index + 1);
        const formattedNumber = number < 10 ? `0${number}` : number;
        return <div className="sr-no">{formattedNumber}</div>;
      },
      maxSize: 15,
      minSize: 10,
    });

    dynamiColumns?.forEach((col) => {
      if (col.accessorKey === "preview") {
        // Customize the Cell renderer for the actions column
        columns.push({
          ...col,
          Cell: ({ row }) => (
            <div onClick={() => handlePreviewClick(row?.original)}>
              <a role="button" className="text-decoration-underline">
                Preview
              </a>
            </div>
          ),
        });
      } else if (col.accessorKey === "map") {
        // Customize the Cell renderer for the actions column
        columns.push({
          ...col,
          Cell: ({ row }) => (
            <div onClick={() => handlePreviewClick(row?.original)}>
              <a role="button" className="text-decoration-underline">
                Map
              </a>
            </div>
          ),
        });
      } else if (col.accessorKey === "voicenote") {
        // Customize the Cell renderer for the actions column
        columns.push({
          ...col,
          Cell: ({ row }) => {
            // Extract the relevant data from row.original
            const recordingId = row?.original?.alert_audio_url; // or whatever key holds the dynamic part
            

            return (
              <a
                href={recordingId}
                className="text-decoration-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Speaker />
              </a>
            );
          },
        });
      } else {
        columns.push(col);
      }
    });
    return columns;
  }, [dynamiColumns, calculations]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "max-content",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    height:"500px",
    overflow:"auto"
  };

  // Define a function to remove specific items from the array
  function removeItems(array, itemsToRemove) {
    return array.filter((item) => !itemsToRemove.includes(item));
  }

  const finalArray = extractKeysFromArray(
    state?.data,
    removeItems(columnOrder, excludeKeys)
  );
  const newArray = changeArrayKeys(finalArray, keyMapping);

  newArray &&
    newArray?.forEach((obj) => {
      delete obj.Shift;
    });

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 10 });
  }, [endPoint, title]);
  
  return (
    <>
      <div className="main">
        <HeaderLayout title={title} />
        <section class="user-listmain content web">
          <>
            <Listfilter
              setGlobalFilter={setGlobalFilter}
              filterOption={filterOption}
              title={title}
              isExport={true}
              data={addSerialNumber(newArray, pagination)}
            />
            <MaterialDataGrid
              columns={dynamicColumns}
            //   data={state?.data}
              data={endPoint === "getsalaryreport" ? state?.data?.data : state?.data}
              rowCount={state?.rowCount}
              isError={state?.isError}
              handleColumnFilter={setColumnFilters}
              handlePagination={setPagination}
              initialState={{
                columnFilters: columnFilters,
                isLoading: state?.isLoading,
                pagination: pagination,
                showAlertBanner: state?.isError,
                showProgressBars: state?.isRefetching,
                columnOrder: columnOrder ? columnOrder : [],
              }}
            />
          </>
        </section>

        <FooterLayout />

        <Modal
          open={modal?.isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ position: "elative", overflow: "hidden" }}>
            <IconButton
              sx={{ position: "absolute", top: 8, right: 8 }}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {modal?.header}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {location?.pathname === "/attendanceReports" ? (
                  <>
                  <div className="row">
                  <div class="col">
                    <label>Check-In Image:</label> <br />
                    <img
                      src={modal.data?.checkin_img_name}
                      width="300" height="300" alt="No Image Found"
                      loading="lazy"
                      rel="noopener noreferrer"
                    />
                    </div>

                    <div class="col">
                    <label>Check-Out Image:</label> 
                    <img
                      src={modal.data?.checkout_img_name}
                      width="300" height="300" alt="No Image Found"
                      loading="lazy"
                      rel="noopener noreferrer"
                    />
                    </div>
                    </div>
                  </>
                ): (
                  <>
                    {modal.data?.guard_img_url ? (
                      <>
                        <label>Guard Image:</label> <br />
                        <img
                          src={modal.data?.guard_img_url}
                          width="300"
                          height="300"
                          alt="Guard Image"
                          loading="lazy"
                          rel="noopener noreferrer"
                        />
                      </>
                    ) : (
                      <div className="image-grid">
                        {modal?.data?.checkpoint_data?.map((ele, index) => (
                          <div className="image-item" key={index}>
                            <label>{ele?.checkpoint_name}: </label> <br />
                            <img
                              src={ele?.checkpoint_img_url}
                              width="300"
                              height="300"
                              alt={ele?.checkpoint_name}
                              loading="lazy"
                              rel="noopener noreferrer"
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </Typography>
            </Box>
          </Box>
        </Modal>
        <style jsx>{`
        .image-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 10px;
        }
        .image-item {
          text-align: center;
        }
        .row {
          display: flex;
          justify-content: space-between;
        }
        .col {
          flex: 1 0 45%;
          margin: 10px;
          text-align: center;
        }
      `}</style>
      </div>
    </>
  );
};
export default ReportsList;
